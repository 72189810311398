import './CSS/Hero.css'; 
import { useTranslation } from 'react-i18next';
import '../Componentes/IMG/img.pt.png';

import imageMap from './imageMap';


const Hero = () => {
 
 
  const {t,i18n} =useTranslation();
  

  const currentLanguage = i18n.language;
  const imageSrc = imageMap[currentLanguage] || imageMap['pt']
  return (
    <section id="hero" className="hero">
      <div className="container">
        <img className='ptBr-img' src={imageSrc}/>
        <h1>{t('welcome')}</h1>
        <h2>{t('convite')} 🌎</h2>
      </div>

        
        
    </section>
    
  );
 
};

export default Hero;
