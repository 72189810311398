import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from './IMG/Logo.png';
import seta from '../Componentes/IMG/seta-para-baixo.png';
import bandpt from '../Componentes/IMG/band-pt.png';
import banden from '../Componentes/IMG/band-en.png';
import bandjp from '../Componentes/IMG/bandjp.png';
import bandsp from '../Componentes/IMG/bandsp.png';
import bandfr from '../Componentes/IMG/band-fr.png';
import { Link } from 'react-router-dom';
import './CSS/Header.css'; // Para estilizar o Header

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // Função para alternar a visibilidade do menu de idiomas
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Função para alterar o idioma e fechar o menu
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsVisible(false); // Fecha o menu ao trocar o idioma
  };

  // Função para selecionar a bandeira com base no idioma atual
  const getCurrentFlag = () => {
    switch (i18n.language) {
      case 'ptBr':
        return bandpt;
      case 'en':
        return banden;
      case 'jp':
        return bandjp;
      case 'sp':
        return bandsp;
      case 'fr':
        return bandfr;
      default:
        return bandpt; // Bandeira padrão
    }
  };

  // Função para obter o código do idioma com base no idioma atual
  const getCurrentLanguageCode = () => {
    switch (i18n.language) {
      case 'ptBr':
        return 'PT';
      case 'en':
        return 'EN';
      case 'jp':
        return 'JP';
      case 'sp':
        return 'SP';
      case 'fr':
        return 'FR';
      default:
        return 'Portguês'; // Código padrão
    }
  };

  return (
    <header className="header">
      <div className="container">
        <img className='logo-ti' src={logo} alt="Logo" />
        <nav>
          <ul className='pra'>
            <li className='pri'><a href="/login">{t('login')}</a></li>
            <li className='pri'><Link to="/cadastro">{t('cadastro')}</Link></li>
            <li className='pri'><a href="#features">{t('Quemsomos')}</a></li>
            <div className='Selec'>
              <a onClick={toggleVisibility} className='lang-selector'>
                <img className='lang' src={getCurrentFlag()} alt="Idioma atual" />
                <span className='lang-code'>{getCurrentLanguageCode()}</span>
                <img className='seta' src={seta} alt="Seta para baixo" />
              </a>
              {isVisible && (
                <div className='LangOptions'>
                  <ul className='idiomas'>
                    <li>
                      <a href='' onClick={() => changeLanguage('ptBr')} className='nb-pt'>
                        <img className='band-pt' src={bandpt} alt="Português" />
                        Português
                      </a>
                    </li>
                    <li>
                      <a href='' onClick={() => changeLanguage('en')} className='nb-en'>
                        <img className='band-en' src={banden} alt="Inglês" />
                        Inglês
                      </a>
                    </li>
                    <li>
                      <a href='' onClick={() => changeLanguage('jp')} className='nb-jp'>
                        <img className='band-jp' src={bandjp} alt="Japonês" />
                        Japonês
                      </a>
                    </li>
                    <li>
                      <a href='' onClick={() => changeLanguage('sp')} className='nb-sp'>
                        <img className='band-sp' src={bandsp} alt="Espanhol" />
                        Espanhol
                      </a>
                    </li>
                    <li>
                      <a href='' onClick={() => changeLanguage('fr')} className='nb-fr'>
                        <img className='band-fr' src={bandfr} alt="Francês" />
                        Francês
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
