import { useState, useRef, useEffect } from "react";
import '../CSS/verify.css';
import verify from '../IMG/verify.png'
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";
import toast from "react-hot-toast";
function Verify() {
  const {t} = useTranslation()
  const [code,setCode] = useState(["","","","","",""]);
  const inputRefs = useRef([])
  const navigate= useNavigate();

  const {error, verifyEmail} = useAuthStore()
  const handleChange = (index,value) => {
    const newCode = [...code]
    if(value.length > 1){
      const pastedCode = value.slice(0,6).split("");
      for (let i = 0; i < 6; i++){
        newCode[i] = pastedCode[i] || "";
      }
      setCode(newCode);
      const lastFilledIndex = newCode.findLastIndex((digit)=> digit !== "");
      const focusIndex = lastFilledIndex < 5 ? lastFilledIndex + 1: 5;
      inputRefs.current[focusIndex].focus();
    } else{
      newCode[index] = value;
      setCode(newCode);
      if(value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");
    try {
      await verifyEmail(verificationCode);
      navigate('/temp')
      toast.success(`${t('sucesso')}`)
    } catch (error) {
      console.log(error)
    }
   
  }

const handleKeyDown = (index, e) =>{
  if(e.key ==="Backspace" && !code[index] && index > 0){
    inputRefs.current[index - 1].focus();
  }
};
useEffect(()=>{
  if(code.every(digit => digit !== "")){
    handleSubmit( new Event ('submit'));
  }
}, [code])
    return (
      <div className="App">
        
      <div className="verify">
        <h1 className="verifique">{t('verifique')}</h1>
        <p className="digitos">{t('digitos')}</p>
       <form onSubmit={handleSubmit}>
       {code.map((digit,index)=>(
       <input className="number"
       key={index}
       ref={(el)=> (inputRefs.current[index]=el)}
       type="text"
       maxLength='6'
       value={digit}
       onChange={(e)=>handleChange(index,e.target.value)}
       onKeyDown={(e)=> handleKeyDown(index,e)}
       ></input> 
      
      ))} 
       </form>
       <img className="ft-verify" src={verify}/><br/>
       <button className="btn-verify">{t('btn-verify')}</button>

       {error && <p className ='text-error'>{error}</p>}
      </div>
      </div>
    );
  }
  export default Verify;