import { useState } from "react";
import '../CSS/Cadastro.css';
import { useTranslation } from "react-i18next";
import imgcadastro from '../IMG/img-cadastro.png'
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";

const Cadastro = () => {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const { cadastro, error } = useAuthStore();
  const navigate = useNavigate();
 const handleSubmitCadastro = async (e)=>{
  e.preventDefault();
  try {
    await cadastro (email,password);
    navigate("/verificar-email")
  } catch (error) {
    console.log(error)
  }
 };
  const {t} = useTranslation();
    return (
      <div className="container-cadastro">
        <img 
          className="ft-cadastro" 
          src={imgcadastro} 
          alt="Imagem de Cadastro" 
        />
        <form 
          action="/cadastro/register" 
          method="post" 
          className="formCadastro"
          onSubmit={handleSubmitCadastro}
        >
          <div className="cadastro">
            <h1 className="cadastro-texto">{t('cadastro')}</h1>
            <img
            className="icon-email"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAbNJREFUSEvdlTFoFEEUhr9/o+yeFqlSiCCEFImFvY1dSkE4g52ClXqbCEIKbSRYRRAE7zZoJWgnMTZilc7GWoukClEEi1QpzO2i2V92zcWLJkcu5zU33c7M+7/937x5I/o81Gd9BgjQILsE+bzERE9pMytGs9NE7wqd3RQlbH1FOt2TeCvYrMVUxvYC1HQxkTs4O0O4ehRQnWwiUL5SxMaulD//x8EOwOa7CK7FhEvdQBKyqslfSJzsCNgVtR6NEN69grY7gV7hoQ2yeeTZ9n0HOsBcNzyVCDHvt4ku30Yb+0Ge4JEh0teICzaZ4CbieUcHBblOdk7kbyXOYH8TVGuc+NAOWWDrvGEJ6ZTNFxNcnCH8lOyk+kAHrYVnePgn6SJiEvPD6M40UVJAGqSx8GPEcczyMaKpG2izWDs0oNhsrIR0TuJ++W1elpUhrpZuzFyN6IFQWYFdA1pBC6STJl9EGv4t7E0RTNWIlv8+m64ctAfXaY4KvxHkAareorK+38EfGXDYOzHAgAbNz2Xd/49h1mMqo3t6UdGuRf4QMd4Tw/5ognv/tOueRDsED9CT2a8U/QJ2t84ZX68YMgAAAABJRU5ErkJggg==" 
              alt="Ícone de Email" 
            />
            <input 
              type="email" 
              name="email" 
              placeholder={t('e-mail')}
              value={email}
              onChange={(e)=> setEmail(e.target.value)}
            />
            <img
            className="icon-email"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAbNJREFUSEvdlTFoFEEUhr9/o+yeFqlSiCCEFImFvY1dSkE4g52ClXqbCEIKbSRYRRAE7zZoJWgnMTZilc7GWoukClEEi1QpzO2i2V92zcWLJkcu5zU33c7M+7/937x5I/o81Gd9BgjQILsE+bzERE9pMytGs9NE7wqd3RQlbH1FOt2TeCvYrMVUxvYC1HQxkTs4O0O4ehRQnWwiUL5SxMaulD//x8EOwOa7CK7FhEvdQBKyqslfSJzsCNgVtR6NEN69grY7gV7hoQ2yeeTZ9n0HOsBcNzyVCDHvt4ku30Yb+0Ge4JEh0teICzaZ4CbieUcHBblOdk7kbyXOYH8TVGuc+NAOWWDrvGEJ6ZTNFxNcnCH8lOyk+kAHrYVnePgn6SJiEvPD6M40UVJAGqSx8GPEcczyMaKpG2izWDs0oNhsrIR0TuJ++W1elpUhrpZuzFyN6IFQWYFdA1pBC6STJl9EGv4t7E0RTNWIlv8+m64ctAfXaY4KvxHkAareorK+38EfGXDYOzHAgAbNz2Xd/49h1mMqo3t6UdGuRf4QMd4Tw/5ognv/tOueRDsED9CT2a8U/QJ2t84ZX68YMgAAAABJRU5ErkJggg==" 
              alt="Ícone de Confirmação de Email" 
            />
            <input 
              type="email" 
              name="confirmeEmail" 
              placeholder={t('confirm')} 
            />
            <img 
            className="icon-senha-cadastro"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAUJJREFUSEvtlbFKA0EQhr8/CN6lFQsRBLHQ1lew0c5GfAWRu0rSSBpFC8XC5lbwEazsBAVfwcLGThTESixzCcSMxESISdxLDiII2XJ39v9mf2ZmxYiXRqzPQABHbcNobEusfCVk3BoFFzN5mZVgJsBRPUFW6itk7EWE+z6IF5BQ2ZR00c76AIKzllg1RpRb+1qNCG5+g3gBjsod0jJmOxHF004RR1pGHJpxHROu5QIkpB8ShQkLprfQW6fIOTZTV/XVzN5jilO5AE6pNS9GFvZ9adZ5867fon8JSKitQ+NIYimrxn+cGw+GSjHBVed+j0WOygvS7FDi38HGY0S44Ae0fc8F6FMQvS8YA7p6ZmxRT7F1j5W/tyghfZaYy9UHxlNEOO9ttOaoEI1jxOJQELN7o7CbOSqGEh0gOPNPHkDDG/IJqGOFGUZ4F/kAAAAASUVORK5CYII=" 
              alt="Ícone de Senha" 
            />
            <input 
              type="password" 
              name="password" 
              placeholder={t('Senha')}
              value={password}
              onChange={(e)=> setPassword(e.target.value)}
            />
            <br />
            <br />
            <button type="submit" className="entrar">{t('cadastrar')}</button>
          
            <br />
          </div>
        </form>
        <h3>
        {error && <p className ='text-error-cadastro'>{error}</p>}

          {t('temconta')}
          <Link to="/login" className="temconta">{t('irlogin')}</Link>
          
        </h3>
        <Link to="/" className="home">{t('irhome')}</Link>
      </div>
    );
  };
  
  export default Cadastro;