import i18n from'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import enJson from './translation/en.json'
import frJson from './translation/fr.json'
import ptBrJson from './translation/ptBr.json'
import spJson from './translation/sp.json'
import jpJson from'./translation/jp.json'

i18n
.use(LanguageDetector)
.use(initReactI18next).init({
    fallbackLng:"ptBr",
    interpolation:{
    escapeValue: false,
    },
    resources:{
        en:enJson,
        fr:frJson,
        ptBr:ptBrJson,
        sp:spJson,
        jp:jpJson         
        
    },
});

export default i18n;