import { useState } from "react";
import { useTranslation } from "react-i18next";
import '../CSS/login.css';
import ftlogin from '../IMG/img-login.png'
import { Link, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";

function Login() {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const {login, error} = useAuthStore();
const navigate = useNavigate();
const handleLogin = async (e) => {
  e.preventDefault();
  try {
    await login (email, password)
    navigate("/temp")
  } catch (error) {
    console.log(error)
  }
 
}

  const {t} = useTranslation();
    return (
      <div className="App">
      <div className="container-login">
        <img className="ft-login" src={ftlogin} alt="" />
  
        <form onSubmit={handleLogin}>
  
          <div className="login">
            <h1 className="login-texto">{t('login')}</h1>
            <img className='icon-email'
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAbNJREFUSEvdlTFoFEEUhr9/o+yeFqlSiCCEFImFvY1dSkE4g52ClXqbCEIKbSRYRRAE7zZoJWgnMTZilc7GWoukClEEi1QpzO2i2V92zcWLJkcu5zU33c7M+7/937x5I/o81Gd9BgjQILsE+bzERE9pMytGs9NE7wqd3RQlbH1FOt2TeCvYrMVUxvYC1HQxkTs4O0O4ehRQnWwiUL5SxMaulD//x8EOwOa7CK7FhEvdQBKyqslfSJzsCNgVtR6NEN69grY7gV7hoQ2yeeTZ9n0HOsBcNzyVCDHvt4ku30Yb+0Ge4JEh0teICzaZ4CbieUcHBblOdk7kbyXOYH8TVGuc+NAOWWDrvGEJ6ZTNFxNcnCH8lOyk+kAHrYVnePgn6SJiEvPD6M40UVJAGqSx8GPEcczyMaKpG2izWDs0oNhsrIR0TuJ++W1elpUhrpZuzFyN6IFQWYFdA1pBC6STJl9EGv4t7E0RTNWIlv8+m64ctAfXaY4KvxHkAareorK+38EfGXDYOzHAgAbNz2Xd/49h1mMqo3t6UdGuRf4QMd4Tw/5ognv/tOueRDsED9CT2a8U/QJ2t84ZX68YMgAAAABJRU5ErkJggg=="
              alt="" 
            />
            <input type="email"  value={email} onChange={(e)=> setEmail(e.target.value)} name="email" placeholder={t('e-mail')} required  />
            <img 
            className='icon-senha'
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAUJJREFUSEvtlbFKA0EQhr8/CN6lFQsRBLHQ1lew0c5GfAWRu0rSSBpFC8XC5lbwEazsBAVfwcLGThTESixzCcSMxESISdxLDiII2XJ39v9mf2ZmxYiXRqzPQABHbcNobEusfCVk3BoFFzN5mZVgJsBRPUFW6itk7EWE+z6IF5BQ2ZR00c76AIKzllg1RpRb+1qNCG5+g3gBjsod0jJmOxHF004RR1pGHJpxHROu5QIkpB8ShQkLprfQW6fIOTZTV/XVzN5jilO5AE6pNS9GFvZ9adZ5867fon8JSKitQ+NIYimrxn+cGw+GSjHBVed+j0WOygvS7FDi38HGY0S44Ae0fc8F6FMQvS8YA7p6ZmxRT7F1j5W/tyghfZaYy9UHxlNEOO9ttOaoEI1jxOJQELN7o7CbOSqGEh0gOPNPHkDDG/IJqGOFGUZ4F/kAAAAASUVORK5CYII="
              alt="" 
            />
            <input type="password" value={password} onChange={(e)=> setPassword(e.target.value)}  name="password" placeholder={t('Senha')} required />
            <button type="submit"  className="entrar">{t('entrar')}</button>

            {error && <p className ='text-error-login'>{error}</p>}

          </div>
          <Link className="esqueceu" to="/forget-password">{t('esqueceu')}</Link> <br/>
          <h3>
            {t('txircadastro')} 
            <Link to="/cadastro" className="criar">{t('ircadastro')}</Link></h3>
        </form>
        <Link to="/" className="home">{t('irhome')}</Link>
      </div>
      </div>
    );
  }
  export default Login;