import pt from "./IMG/pt.png"
import en from "./IMG/en.png"
import fr from "./IMG/fr.png"
import jp from "./IMG/jp.png"
import sp from "./IMG/sp.png"


const imageMap = {
    pt: pt,
    en: en,
    sp: sp,
    fr: fr,
    jp: jp
  };
export default imageMap;