import './CSS/Features.css'; // Para estilizar o Features
import { useTranslation } from 'react-i18next';

const Features = () => {
  const {t}=useTranslation();
  return (
    <section id="features" className="features">
      <div className="container">
        <img
        className='ft-alvo'
        src='https://img.freepik.com/vetores-premium/anel-de-alvo-de-tiro-com-arco-com-flecha-acertando-alvo-alvo-no-tripe-conceito-de-realizacao-de-objetivo_254622-357.jpg?w=740'
        />
        <div className="feature-item">
          <p className='texto-missao'>{t('quemsomos')}</p>
        </div>
        
        </div>
    </section>
  );
};

export default Features;
