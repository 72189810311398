import './CSS/Footer.css'; // Para estilizar o Footer

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <p>&copy; 2024 Viewers Community. All rights reserved.</p>
        <p>CNPJ: 57.105.008/0001-30</p>
        <div className="social-links">
          <a href="#">Facebook</a>
          <a href="#">Discord</a>
          <a href="#">Instagram</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
