import '../CSS/temp.css'
import { useTranslation } from "react-i18next";
import temp from "../IMG/temp.gif"
function Temp()  {
  const {t,i18n} = useTranslation();
    return (
      <div className="App">
        <div className="boas">
        {t('boas')}
        <img className="ft-temp" src={temp}></img>
        </div>
      </div>
    );
   
  }
  export default Temp;