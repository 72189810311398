import './Componentes/CSS/App.css';
import  {useEffect } from 'react';
import './Componentes/CSS/login.css';
import './Componentes/CSS/Hero.css';
import './Componentes/CSS/Header.css';
import './Componentes/CSS/Footer.css';
import './Componentes/CSS/Features.css';
import './Componentes/CSS/Cadastro.css';
import './Componentes/CSS/index.css';
import'./Componentes/CSS/temp.css'
import { useTranslation } from 'react-i18next';
import Header from './Componentes/Header';
import Hero from './Componentes/Hero';
import Features from './Componentes/Features';
import Footer from './Componentes/Footer';
import { useAuthStore } from './store/authStore.js';
import { Navigate,useNavigate } from "react-router-dom";
export const RedirectAuthenticatedUser = ({children}) =>{
  const {isAuthenticated,user} = useAuthStore();

  if(isAuthenticated && user.isVerified){
    return <Navigate to = "/temp" replace/>
  }

  return children

}
export const ProtectedRoute = ({children}) =>{
  const {isAuthenticated,user} = useAuthStore();

  if(!isAuthenticated){
    return <Navigate to = "/login" replace/>
  }
  if(!user.isVerified){
    return <Navigate to= "/verficar-email" replace/>
  }

  return children;
}
function App() {
  const {t,i18n} = useTranslation();
  const {isCheckingAuth, isAuthenticated, checkAuth, user} = useAuthStore();

  useEffect(()=>{
    checkAuth()
  },[checkAuth]);
  console.log("isauthenticated", isAuthenticated);
  console.log("user", user);
  return (
   
    <div className="App">
    <Header />
      <Hero />
      <Features />
      <Footer />
    </div>
  );
}

export default App;
