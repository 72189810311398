import {create} from 'zustand';
import axios from 'axios';

const API_URL = "195.35.19.178:8000";

axios.defaults.withCredentials = true;
export const useAuthStore = create ((set)=> ({
    user:null,
    isAuthenticated:false,
    error:null,
    isCheckingAuth:true,

    cadastro: async (email,password) => {
        try {
           const response = await axios.post(`${API_URL}/cadastro`, {email,password});
           set({user:response.data.user, isAuthenticated:true })
        } catch (error) {
            set({error:error.response.data.message || "Usuário Já existe"});
            throw error
        }
    },

    login:async(email,password)=>{

        set({erro:null});
            try {
                const response = await axios.post(`${API_URL}/login`,{email, password});
                set({
                    isAuthenticated:true,
                    user: response.data.user,
                    erro: null
                })
            } catch (error) {
                console.log(error)
                set({error:error.response?.data?.message || "Usuário ou senha inválido"});
                throw error;
            }


    },

    verifyEmail: async (code)=>{
        set({error:null});
        try {
            const response =  await axios.post(`${API_URL}/verficar-email`, {code})
            set({user: response.data.user, isAuthenticated:true});
            return response.data
        } catch (error) {
            set({error:error.response.data.message|| "Erro ao Verificar seu e-mail"})
            throw error;
        }
    },

    forgotPassword: async (email)=>{
        set({error: null, message:null})
        try {
            const response = await axios.post(`${API_URL}/forgot-password`,{email})
            set({message: response.data.message})
        } catch (error) {
            set({error:error.response.data.message|| "Erro ao enviar o email de reset"})
           throw error; 
        }
    },
    resetPassword: async(token, password)=>{
        set({error:null})
try {
    const response = await axios.post (`${API_URL}/reset-password/${token}`,{password});
        set({message: response.data.message})
} catch (error) {
    set({error:error.response.data.message || "Erro ao mudar a senha"});
    throw error;
}

    }
,
    checkAuth: async()=>{
        set({isCheckingAuth: true, error: null})
        try {
            const response = await axios.get(`${API_URL}/check-auth`);
            set({user: response.data.user, isAuthenticated:true, isCheckingAuth: false});
        } catch (error) {
            set({error: null, isCheckingAuth:false, isAuthenticated:false})
        }
    }
}))
