import React from 'react';
import Temp from'./Componentes/routes/temp.js'
import Cadastro from './Componentes/routes/cadastro';
import Login from './Componentes/routes/login';
import Verify from './Componentes/routes/verficarEmail.js'
import Forgot  from './Componentes/routes/forget.js';
import ReactDOM from 'react-dom/client';
import './Componentes/CSS/index.css';
import App from './App.js';
import './i18n/index.ts'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './manifest.json'
import {Toaster} from 'react-hot-toast'
import {RedirectAuthenticatedUser,ProtectedRoute} from'./App.js'
import Reset from './Componentes/routes/resetPassword.js';


const router = createBrowserRouter([
  
  {
    path: "/",

    element:
    
    <App/>
  
  },
  {
    path:"/login",
    
    element:
    <RedirectAuthenticatedUser>
    <Login/>
    </RedirectAuthenticatedUser>
  },
  {
    path:"/cadastro",
    element:
    <RedirectAuthenticatedUser>
    <Cadastro/>
    </RedirectAuthenticatedUser>
  },
 {
  path:"/temp",
  element:
  <ProtectedRoute>
    <Temp/>
  </ProtectedRoute>
 },
 {
  path:"/verificar-email",
 
  element:
  <RedirectAuthenticatedUser>
  <Verify/>
  </RedirectAuthenticatedUser>
 },
 {
  path:"/forget-password",
  element: 
  <RedirectAuthenticatedUser>
  <Forgot/>
  </RedirectAuthenticatedUser>
 },
 {
  path:"reset-password/:token",
  
  element:
  <RedirectAuthenticatedUser>
  <Reset/>
  </RedirectAuthenticatedUser>
 }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
    <Toaster/>
  </React.StrictMode>
);